<pp-settings-container *ngIf="workspace">
  <pp-settings-header>
    <header class="settingsSite__header">
      <div *ngIf="fromAccount" class="previousPageBtnContainer">
        <a class="backToSite linkButton" (click)="goToPreviousPage()">
          <pp-icon class="backArrow" [ppSrc]="EIconPath.ARROW_BACK"></pp-icon>
          {{ 'back_to_account_settings' | ppTranslate }}</a
        >
      </div>

      <h4 class="settingsSite__acc-name">
        {{ workspace.accountName }}
      </h4>

      <div class="settingsSite__siteName">
        {{ workspace.siteName }}
      </div>

      <ul
        class="settingsSite__tabs"
        *ngIf="
          workspace.share.shareOption === EUserRole.SITE_ADMIN ||
          workspace.share.shareOption === EUserRole.OWNER ||
          workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN ||
          user.isSuperUser
        "
      >
        <li class="settingsSite__tab" routerLinkActive="settingsSite__tab--active">
          <a
            data-m-target="Site settings tags button"
            class="settingsSite__tab-link"
            [routerLink]="['/settings/site/', workspaceId, ESiteSettingsPaths.TAGS]"
          >
            {{ 'tags' | ppTranslate }}
          </a>
        </li>

        <li class="settingsSite__tab" routerLinkActive="settingsSite__tab--active">
          <a
            data-m-target="Site settings custom fields button"
            class="settingsSite__tab-link"
            [routerLink]="['/settings/site/', workspaceId, ESiteSettingsPaths.CUSTOM_FIELDS]"
          >
            {{ 'custom_fields' | ppTranslate }}
          </a>
        </li>

        <li class="settingsSite__tab" routerLinkActive="settingsSite__tab--active">
          <a
            data-m-target="Site settings users button"
            class="settingsSite__tab-link"
            [routerLink]="['/settings/site/', workspaceId, ESiteSettingsPaths.USERS]"
          >
            {{ 'users' | ppTranslate }}
          </a>
        </li>

        <li
          class="settingsSite__tab"
          routerLinkActive="settingsSite__tab--active"
          *ngIf="workspace.share.shareOption === EUserRole.OWNER || user.isSuperUser"
        >
          <a
            data-m-target="Site settings site plan button"
            class="settingsSite__tab-link"
            [routerLink]="['/settings/site/', workspaceId, ESiteSettingsPaths.PLAN]"
          >
            {{ 'site_plan' | ppTranslate }}
          </a>
        </li>

        <li
          class="settingsSite__tab"
          routerLinkActive="settingsSite__tab--active"
          *ngIf="workspace.share.shareOption === EUserRole.OWNER || user.isSuperUser"
        >
          <a
            data-m-target="Site settings site plan button"
            class="settingsSite__tab-link"
            [routerLink]="['/settings/site/', workspaceId, ESiteSettingsPaths.LOGO]"
          >
            {{ 'pdf_logo_header' | ppTranslate }}
          </a>
        </li>

        <li class="settingsSite__tab" routerLinkActive="settingsSite__tab--active">
          <a
            data-m-target="Site settings integrations button"
            class="settingsSite__tab-link"
            [routerLink]="['/settings/site/', workspaceId, ESiteSettingsPaths.INTEGRATIONS]"
          >
            {{ 'integrations' | ppTranslate }}
          </a>
        </li>
      </ul>
    </header>
  </pp-settings-header>

  <ng-container
    *ngIf="
      workspace.share.shareOption === EUserRole.SITE_ADMIN ||
      workspace.share.shareOption === EUserRole.OWNER ||
      workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN ||
      user.isSuperUser
    "
  >
    <router-outlet></router-outlet>
  </ng-container>
</pp-settings-container>
